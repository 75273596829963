import React from "react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { motion, useAnimate, useInView } from "framer-motion";
import {
  Bird,
  Send,
  Bot,
  Refrigerator,
  CircleEllipsis,
  Gem,
  ExternalLink,
} from "lucide-react";
import { Drawer } from "vaul";

export const Route = createFileRoute("/")({
  component: Index,
});

function Index() {
  return (
    <div className="bg-cetaceanblue flex-col flex gap-4">
      <div className="px-4">
        <Topnav />
      </div>
      <div className="px-4">
        <Hero />
      </div>
      <div className="px-4">
        <Fish />
      </div>
      <Footer />
    </div>
  );
}

function Fish() {
  return (
    <div className="flex flex-col lg:flex-row gap-4">
      <div className="bg-[#F090CA] p-10 lg:p-20 rounded-3xl w-full flex flex-col gap-4">
        <a
          target="_blank"
          href="https://kairoscrypto.substack.com/p/eigenfi-and-ebisu-the-inevitable"
        >
          <h2 className="hover:text-purple-600 text-cetaceanblue text-4xl lg:text-[4em] font-bold tracking-tight leading-none">
            <span className="text-floralwhite">EigenFi & Ebisu; </span> The
            Inevitable DeFi Ecosystem of EigenLayer
          </h2>
        </a>
        <p className="text-xl">
          EigenLayer brings a new incentive layer on top of Ethereum; It is
          inevitable we see a DeFi ecosystem establish itself around restaking
          yield. Studying the past gives us a glimpse of the future
        </p>

        <div
          style={{
            border: "6px solid #A987FF",
            borderRadius: "0 210px 210px 210px",
          }}
          className="border-2 overflow-hidden pt-8 justify-center flex"
        >
          <img className="w-[80%]" src="/fishy.svg" alt="" />
        </div>

        <div className="flex items-center gap-2">
          <a
            className="underline text-xl hover:text-floralwhite"
            href="https://kairoscrypto.substack.com/p/eigenfi-and-ebisu-the-inevitable"
          >
            Read more on Karios Research
          </a>
          <ExternalLink size={20} />
        </div>
      </div>

      <div className="bg-[#AB80F8] p-10 lg:p-20 rounded-3xl w-full flex flex-col gap-4">
        <a
          target="_blank"
          href="https://www.shoal.gg/p/underwriting-lrts-balancing-high"
        >
          <h2 className="text-pretty hover:text-pink-300 text-cetaceanblue text-4xl lg:text-[4em] font-bold tracking-tight leading-none">
            <span className="text-floralwhite">Underwriting LRTs:</span>{" "}
            Balancing High Yield with Novel Risks
          </h2>
        </a>

        <p className="text-xl">
          Underwriting the risk of Eigenlayer liquid restaking tokens within the
          DeFi ecosystem
        </p>

        <img className="w-full" src="/shoal.png" alt="" />

        <div className="flex items-center gap-2">
          <a
            className="underline text-xl hover:text-floralwhite"
            href="https://www.shoal.gg/p/underwriting-lrts-balancing-high"
          >
            Read more on shoal.gg
          </a>
          <ExternalLink size={20} />
        </div>
      </div>
    </div>
  );
}

function Topnav() {
  return (
    <div className="w-full flex justify-between items-center gap-4 z-[999] pt-4">
      <a href="/">
        <img className="w-[90px] hidden lg:block" src="/logo-dark.svg" alt="" />
        <img className="w-[40px] lg:hidden" src="/ebisu-pink.svg" alt="" />
      </a>
      <div className="flex items-center gap-2 text-white">
        <Social />
        <SocialDrawer />
        <Link
          className="rounded-full py-1 px-4 bg-rosebonbon text-white font-medium hover:bg-purple-500 flex items-center gap-1"
          to="/points"
        >
          <Gem size={16} />
          <span>Earn Points</span>
        </Link>
      </div>
    </div>
  );
}

const TAGLINES = [
  ["Unleash", "EigenFi"],
  ["Highest", "Yield"],
  ["Lowest", "Fees"],
];

function Hero() {
  const [scope, animate] = useAnimate();
  const isInView = useInView(scope);
  const [tagline, setTagline] = React.useState(TAGLINES[0]);

  React.useEffect(() => {
    if (!isInView) return;
    let count = 0;
    // set interval
    const interval = setInterval(() => {
      count++;
      const line = TAGLINES[count % TAGLINES.length];
      animate("#t1", { x: 0, y: -50, opacity: 0 }, { duration: 1, delay: 2 })
        .then(() => {
          setTagline(line);
        })
        .then(() => animate("#t1", { x: 0, y: 0, opacity: 1 }));
      animate(
        "#t2",
        { x: 0, y: 50, opacity: 0 },
        { duration: 1, delay: 2 },
      ).then(() => animate("#t2", { x: 0, y: 0, opacity: 1 }));
    }, 5000);

    return () => clearInterval(interval);
  }, [isInView]);

  return (
    <div className="text-center rounded-3xl overflow-hidden">
      <div
        style={{
          backgroundImage: "url(/v1/hero.svg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
        }}
        className="bg-[#32C9F5] overflow-hidden flex flex-col justify-between"
      >
        <div className="flex justify-between">
          <div className="relative">
            <div
              ref={scope}
              className="absolute left-10 top-[200px] lg:left-20 lg:top-32 text-[5.5em] sm:text-[8em] lg:text-[10em] xl:text-[11em] font-bold left-[20px] tracking-tight leading-none z-[99] lg:z-[2] text-left"
            >
              <div id="t1" className="text-white">
                {tagline[0]}
              </div>
              <div id="t2" className="text-[#051034]">
                {tagline[1]}
              </div>
            </div>

            <motion.img
              initial={{ x: 0 }}
              animate={{ x: 20 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 4,
              }}
              className="min-w-[260px] absolute right-[-80px] lg:right-[180px] top-[-20px]"
              src="/v1/cloud-1.svg"
              alt=""
            />
          </div>
        </div>

        <div className="flex justify-between">
          <div className="relative lg:mt-20 lg:ml-8 xl:ml-12">
            <motion.img
              initial={{ x: 0, y: 0 }}
              animate={{ x: -20, y: -20 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 4,
              }}
              className="min-w-[110px] hidden lg:block absolute left-[20px] lg:left-[40px] top-[-160px] z-[99]"
              src="/v1/cloud-6.svg"
              alt=""
            />

            <motion.img
              initial={{ x: 0, y: 0 }}
              animate={{ x: 20, y: 20 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 4,
              }}
              className="min-w-[230px] lg:block absolute left-[20px] lg:left-[100px] top-[20px] z-[99]"
              src="/v1/cloud-3.svg"
              alt=""
            />
          </div>

          <div className="w-[1px] h-[1px]">
            <motion.img
              initial={{ x: 0, y: 0, scale: 0.98 }}
              animate={{ x: 10, y: -20, scale: 1.1 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 8,
              }}
              className="absolute top-[60%] lg:top-[35%] right-[10%] lg:right-[30%] h-[40%] lg:h-[60%]"
              src="/v1/fish.svg"
              alt=""
            />
          </div>

          <div className="relative">
            <motion.img
              initial={{ x: -10 }}
              animate={{ x: 0 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 2,
              }}
              className="min-w-[260px] hidden lg:block absolute right-[60px] top-[-160px]"
              src="/v1/cloud-2.svg"
              alt=""
            />
          </div>
        </div>

        <div className="min-h-[1px] flex w-full">
          <div className="w-[70%] relative hidden lg:block">
            <motion.div
              initial={{ x: 0 }}
              animate={{ x: -20 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 3,
              }}
              style={{
                backgroundImage: "url(/v1/wave-left.svg)",
                backgroundSize: "cover",
                backgroundPosition: "top center",
              }}
              className="absolute w-full h-[200px] bottom-0 left-0 z-[3]"
            ></motion.div>
          </div>
          <div className="w-full relative">
            <div className="absolute bottom-0 z-[1] left-[-10px] lg:left-[-80px] lg:bottom-[-22px]">
              <motion.img
                initial={{ y: 0 }}
                animate={{ y: 18 }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 3,
                }}
                className="h-[100px] lg:block"
                src="/v1/eth.svg"
                alt=""
              />
            </div>
            <motion.div
              initial={{ x: -20, y: 0 }}
              animate={{ x: 0, y: 5 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 3,
              }}
              style={{
                backgroundImage: "url(/v1/wave-right.svg)",
                backgroundSize: "cover",
                backgroundPosition: "top center",
              }}
              className="absolute w-[728px] lg:w-full h-[220px] bottom-0 lg:left-[-20px] z-[50]"
            ></motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Social() {
  return (
    <div className="items-start md:justify-center md:items-center gap-2 font-medium hidden lg:flex">
      <a
        className="flex items-center hover:text-rosebonbon flex-col justify-center text-center gap-0 lg:flex-row lg:gap-1"
        target="_blank"
        href="https://twitter.com/Ebisu_Finance"
      >
        <Bird size={14} />
        <span>Twitter</span>
      </a>

      <a
        className="flex items-center hover:text-rosebonbon flex-col justify-center text-center gap-0 lg:flex-row lg:gap-1"
        target="_blank"
        href="https://mirror.xyz/0xE5147053538249EFD3791508A2c8D8BB154C910A"
      >
        <Refrigerator size={14} />
        <span>Mirror</span>
      </a>

      <a
        className="flex items-center hover:text-rosebonbon flex-col justify-center text-center gap-0 lg:flex-row lg:gap-1"
        target="_blank"
        href="https://t.me/Ebisu_Finance_Updates"
      >
        <Send size={14} />
        <span>Telegram</span>
      </a>

      <a
        className="flex items-center hover:text-rosebonbon flex-col justify-center text-center gap-0 lg:flex-row lg:gap-1"
        target="_blank"
        href="https://discord.gg/cHNmskazx8"
      >
        <Bot size={14} />
        <span>Discord</span>
      </a>
    </div>
  );
}

function SocialDrawer() {
  return (
    <Drawer.Root activeSnapPoint={1}>
      <Drawer.Trigger asChild>
        <button className="flex items-center hover:text-rosebonbon justify-center text-center gap-1 items-center lg:hidden">
          <CircleEllipsis size={20} />
        </button>
      </Drawer.Trigger>
      <Drawer.Overlay className="fixed inset-0 bg-black/40 z-[999]" />
      <Drawer.Portal>
        <Drawer.Content className="fixed flex flex-col bg-gray-100 dark:bg-[#041137] rounded-t-2xl bottom-0 left-0 right-0 h-full max-h-[420px] mx-[-1px] dark:text-white z-[999]">
          <div className="items-start md:justify-center md:items-center gap-4 text-xl p-6 flex flex-col">
            <h2 className="text-xl font-bold flex items-center justify-center mb-2">
              Find us on
            </h2>

            <a
              className="flex items-center hover:text-rosebonbon justify-center gap-1 p-2 border-2 rounded-xl w-full"
              target="_blank"
              href="https://twitter.com/Ebisu_Finance"
            >
              <Bird size={18} />
              <span>Twitter</span>
            </a>

            <a
              className="flex items-center hover:text-rosebonbon justify-center gap-1 p-2 border-2 rounded-xl w-full"
              target="_blank"
              href="https://mirror.xyz/0xE5147053538249EFD3791508A2c8D8BB154C910A"
            >
              <Refrigerator size={18} />
              <span>Mirror</span>
            </a>

            <a
              className="flex items-center hover:text-rosebonbon justify-center gap-1 p-2 border-2 rounded-xl w-full"
              target="_blank"
              href="https://t.me/Ebisu_Finance_Updates"
            >
              <Send size={18} />
              <span>Telegram</span>
            </a>

            <a
              className="flex items-center hover:text-rosebonbon justify-center gap-1 p-2 border-2 rounded-xl w-full"
              target="_blank"
              href="https://discord.gg/cHNmskazx8"
            >
              <Bot size={18} />
              <span>Discord</span>
            </a>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

function Footer() {
  return (
    <div className="min-h-[520px] lg:min-h-[520px] text-floralwhite relative mt-12 lg:mt-32">
      <div className="flex px-4 justify-center gap-12 lg:gap-20">
        <div className="flex flex-col">
          <h3 className="text-2xl font-bold">Ebisu</h3>
          <a
            target="_blank"
            className="hover:text-rosebonbon text-lg"
            href="https://mirror.xyz/0xE5147053538249EFD3791508A2c8D8BB154C910A"
          >
            Mirror
          </a>
          <a
            target="_blank"
            className="hover:text-rosebonbon text-lg"
            href="https://drive.google.com/drive/folders/1ZxPPSV3DcWxJa_OIulslXK1_5hKvDU78"
          >
            Brand Kit
          </a>
          <a
            target="_blank"
            className="hover:text-rosebonbon text-lg"
            href="https://x.com/LaborXNews/status/1792942468167819759"
          >
            We're hiring
          </a>
        </div>

        <div className="flex flex-col">
          <h3 className="text-2xl font-bold">Audits</h3>
          <a
            target="_blank"
            className="hover:text-rosebonbon text-lg"
            href="https://ebisu.finance/PeckShield-Audit-Report-EbisuVault-v1.0.pdf"
          >
            Peckshield
          </a>
          <a
            target="_blank"
            className="hover:text-rosebonbon text-lg"
            href="https://github.com/pashov/audits/blob/master/team/pdf/Ebisu-security-review.pdf"
          >
            Pashov
          </a>
        </div>

        <div className="flex flex-col">
          <h3 className="text-2xl font-bold">Social</h3>
          <a
            target="_blank"
            className="hover:text-rosebonbon text-lg"
            href="https://discord.com/invite/cHNmskazx8"
          >
            Discord
          </a>
          <a
            target="_blank"
            className="hover:text-rosebonbon text-lg"
            href="https://x.com/Ebisu_Finance"
          >
            Twitter
          </a>
          <a
            target="_blank"
            className="hover:text-rosebonbon text-lg"
            href="https://t.me/Ebisu_Finance_Updates"
          >
            Telegram
          </a>
        </div>
      </div>

      <div
        style={{
          backgroundImage: "url(/footer@2x.webp)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="min-h-[300px] w-full absolute bottom-0"
      ></div>
    </div>
  );
}
