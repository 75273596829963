import React from "react";
import { Check, Loader2 } from "lucide-react";
import { toast } from "sonner";
import type { ExternalToast } from "sonner";
import { useAccount } from "wagmi";
import { mode, sepolia, mainnet } from "wagmi/chains";

const toastSettings = { duration: 10_000 };

interface LoadingToastProps {
  txHash?: string;
  text?: string;
  t: any;
}

function useScanUrl(txHash?: string) {
  const { chainId } = useAccount();
  const scanUrl = React.useMemo(() => {
    const buildUrl = (chain: any) =>
      `${chain.blockExplorers.default.url}/tx/${txHash}`;
    if (chainId === mainnet.id) {
      return buildUrl(mainnet);
    }
    if (chainId === sepolia.id) {
      return buildUrl(sepolia);
    }
    if (chainId === mode.id) {
      return buildUrl(mode);
    }
  }, [chainId, txHash]);
  return scanUrl;
}

export function LoadingToast(props: LoadingToastProps) {
  const scanUrl = useScanUrl(props.txHash);

  return (
    <div className="w-[356px]">
      <div className="p-4 border-2 border-gray-900 rounded-xl flex flex-col gap-2 overflow-y-auto text-sm bg-purple-100 w-full">
        <div className="flex items-center gap-1">
          <Loader2 size={16} className="animate-spin" />
          <p>{props.text ?? "Loading..."}</p>
        </div>
        {props.txHash && (
          <div className="flex gap-1 items-center justify-center">
            <a
              className="cursor-pointer px-2 py-1 flex items-center justify-center rounded-lg bg-white border-2 border-gray-800 hover:bg-pink-200"
              target="_blank"
              href={scanUrl}
            >
              View on explorer
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

interface LoadingToastInput {
  txHash?: string;
  text?: string;
  id: any;
}

export function loadingToast(input: LoadingToastInput) {
  const instance = toast.custom(
    (t) => <LoadingToast t={t} text={input.text} txHash={input.txHash} />,
    {
      ...toastSettings,
      id: input.id,
      duration: 100_000,
    } as ExternalToast,
  );
  return instance;
}

interface FailToastProps {
  text?: string;
  t: any;
}

export function FailToast(props: FailToastProps) {
  return (
    <div className="w-[356px]">
      <div className="p-4 border-2 border-gray-900 rounded-xl flex flex-col gap-2 overflow-y-auto text-sm bg-red-100">
        <p>{props.text}</p>
      </div>
    </div>
  );
}

interface FailToastInput {
  text: string;
  id: any;
}

export function failToast(input: FailToastInput) {
  toast.custom((t) => <FailToast t={t} text={input.text} />, {
    ...toastSettings,
    id: input.id,
  } as ExternalToast);
}

interface OkToastProps {
  text: string;
  txHash?: string;
  t: any;
}

export function OkToast(props: OkToastProps) {
  const scanUrl = useScanUrl(props.txHash);

  return (
    <div className="w-[356px]">
      <div className="p-4 border-2 border-gray-900 rounded-xl flex flex-col gap-2 overflow-y-auto text-sm bg-green-100 w-full">
        <div className="flex items-center gap-1">
          <Check size={16} />
          <p>{props.text}</p>
        </div>

        <div className="flex gap-1 items-center justify-center">
          {props.txHash && (
            <a
              className="cursor-pointer px-2 py-1 flex items-center justify-center rounded-lg bg-white border-2 border-gray-800 hover:bg-pink-200"
              target="_blank"
              href={scanUrl}
            >
              View on explorer
            </a>
          )}
          <button
            className="cursor-pointer px-2 py-1 flex items-center justify-center rounded-lg bg-white border-2 border-gray-800 hover:bg-pink-200"
            onClick={() => toast.dismiss(props.t)}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}

interface okToastInput {
  text: string;
  id: any;
  txHash?: string;
}
export function okToast(input: okToastInput) {
  toast.custom(
    (t) => <OkToast txHash={input.txHash} t={t} text={input.text} />,
    {
      ...toastSettings,
      id: input.id,
    } as ExternalToast,
  );
}
